import React from 'react';
import BillingEmail from '../../components/Account/BillingEmail';
import PrivateRoute from '../../components/PrivateRoute';
import ContextProvider from '../../components/ContextProvider';

const BillingEmailPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account/billing-email" component={BillingEmail} />
  </ContextProvider>
);

export default BillingEmailPage;
